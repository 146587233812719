

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




@keyframes dot {
  0%, 20% {
    color: rgba(0,0,0,0);
  }
  40% {
    color: black;
  }
  100% {
    color: black;
  }
}

.typing-dot {
  animation: dot 1.3s infinite;
}

.typing-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-dot:nth-child(3) {
  animation-delay: 0.4s;
}
