
.ChatContainer {
  padding-bottom: calc(80px + env(safe-area-inset-bottom)); /* Adjust 20px as needed */
}

:root {
  --doc-height: 100%; /* Default value */
}

html, body {
  padding: 0;
  margin: 0;
  height: 100vh; /* Fallback */
  height: var(--doc-height); /* Actual viewport height */
}

.chat-container {
  min-height: 100vh;  /* Minimum height to cover the viewport */
  background-image: url('./images/pgBlur.jpg'); /* Adjust path as necessary */
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* This keeps the background fixed during scrolling */
}
